@import '../../styles/base.less';
@import '../../styles/color.less';

#rate-cards-empty.container {
  border-radius: @border-radius-base;
  height: 100%;
  background-color: transparent;
  margin: 0px;
  width: 100%;
}

.auth-cards {
  width: 430px;
  height: 600px;
  text-align: center;
  padding: 123px 16px;
}