@import '../../styles/color.less';

.single-select-box {
    padding: 0px !important;
    box-shadow: none !important;
}

.single-select {
    padding: 0px !important;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
    width: fit-content !important;
}

#menu-item-single {
    border-radius: 8px;
    border: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
}

#menu-item-single .ant-menu {
    border-radius: 8px;
}

#menu-item-single .ant-menu li {
    margin: 8px 0px;
}

#menu-item-single .ant-menu .ant-radio-group {
    width: 100%;
}

.single-select-item .ant-select-selector .ant-select-selection-item {
    font-weight: 600;
    color: @grey-2;
    margin-right: 16px;
}

.single-select-item .ant-select-arrow {
    color: @grey-2;
}

.single-select-item .ant-select-selector {
    border: 1px solid @grey-3 !important;
    background-color: transparent !important;
}

#btn-wrapper .ant-btn {
    width: 100%;
    margin-top: 5px;
}

.single-select-label {
    margin-bottom: 8px;
    
    span {
        color: @grey-2;
        font-weight: 600;
    }
}

.single-select-text {
    border: none;
}

.single-select-full-width {
    width: 100%;
}