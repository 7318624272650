@import '../../styles/color.less';

.pie-chart-report {
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 2px solid @grey-5;
  border-radius: 8px;
}

.pie-chart-report-item {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pie-chart-report-total {
  font-weight: 600;
}

.pie-chart-report-circle {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 6px;
}