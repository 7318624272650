@import '../../styles/color.less';

.card-with-title {
  margin-right: 24px;
  .subtitle {
    color: @grey-2;
    font-size: 14px;
    line-height: 20px;
    max-width: 540px;
    margin-bottom: 24px;
    white-space: pre-wrap;
  }
  .icon-svg-information {
    width: 13.33px;
    vertical-align: baseline;
    margin-left: 12px;
    color: @grey-3;
  }
}