@import '../../styles/color.less';

#table-content {
  .clickable td {
    cursor: pointer;
  }

  .antd-table {
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background: #888; 
      border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }

    table {
      border-radius: 0px;
    }
  }

  th {
    background: @grey-5;
    border-radius: 0px;
    border-top: 1px solid #C2C2C2;
    border-bottom: 1px solid #C2C2C2;
    font-weight: 600;
  }

  th::before {
    width: 0px !important;
  }

  .row-header-accounts {
    margin-top: 24px;
    margin-bottom: 24px;
    font-weight: 600;
    ::placeholder {
      color: @grey-3;
      font-weight: 600;
    }
  }
  .search-input {
    width: 200px;
  }
}