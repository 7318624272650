@import '../../styles/color.less';

.report-card {
  .info-icon {
    font-size: 12px;
    margin-left: 6px;
    margin-top: 2px;
    color: @grey-2;
  }
}

.report-card-title {
  font-size: 12px;
  margin-bottom: 0px;
}

.report-card-value {
  font-size: 14px;
  font-weight: 600;
  margin-top: 4px;
  margin-bottom: 0px;
  line-height: 20px;
}