@import '~antd/dist/antd.less';
@import './base.less';
@import './color.less';
/* work-sans-100 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/work-sans-v9-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-100.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-100.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-200 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/work-sans-v9-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-200.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-200.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-300 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/work-sans-v9-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-300.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/work-sans-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-500 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/work-sans-v9-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-500.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-700 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/work-sans-v9-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-700.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-600 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/work-sans-v9-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-800 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/work-sans-v9-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-800.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-900 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/work-sans-v9-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-900.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-100italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 100;
  src: url('../assets/fonts/work-sans-v9-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-100italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-100italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-200italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/work-sans-v9-latin-200italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-200italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-200italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-300italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/work-sans-v9-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-300italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/work-sans-v9-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-500italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/work-sans-v9-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-500italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-600italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/work-sans-v9-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-600italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-700italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/work-sans-v9-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-700italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-800italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/work-sans-v9-latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-800italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-800italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-900italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/work-sans-v9-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/work-sans-v9-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/work-sans-v9-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/work-sans-v9-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/work-sans-v9-latin-900italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}

@font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

// -------- Layout -----------
@layout-body-background: @body-background;
@layout-sider-background: @body-background;
@layout-header-background: @body-background;
@layout-header-height: 68px;

// -------- Menu -----------
@menu-bg: @body-background;
@menu-item-active-border-width: 0px;
@menu-inline-submenu-bg: @body-background;
@menu-item-color: @grey-3;
@menu-item-font-size: 16px;

// -------- input -----------
@input-placeholder-color: @grey-3;
@input-border-color: @grey-5;
@input-bg: @grey-5;
// @input-height-lg: @height-lg;
// @input-height-sm: @height-sm;
// @input-padding-horizontal-sm: 16px;
// @input-padding-horizontal-lg: 16px;
// @input-padding-vertical-sm: 8px;
// @input-padding-vertical-lg: 16px;

// -------- Select -----------
@select-single-item-height-lg: @height-lg;
@select-single-item-height-sm: @height-sm;
@select-background: @grey-5;
@select-border-color: @grey-5;

// -------- Picker -----------
@picker-bg: @grey-5;

// -------- Buttons -----------
@btn-font-weight: 600;

@table-row-hover-bg: @blue-5;

// -------- Global Style -----------
html,
body,
div#root,
div#root > section {
  height: 100vh;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  .spacer {
    height: 16px;
  }
  .spacer-lg {
    height: 24px;
  }
}

.antd-table {
  .ant-table-content {
    padding: 0;
  }
  .ant-table-title {
    border: none !important;
    padding: 0;
  }
  .ant-table-container {
    border-left: none !important;
  }

}

.second-menu-edit {
  color: @red-2;
}

.second-menu-item:hover {
  background-color: @blue-5;
  color: @blue-2;
}

.second-menu-void {
  color: @red-3;
}

.second-menu-void:hover {
  color: @red-2;
  background-color: @red-5;
}

.ant-timeline-item {
  padding-bottom: 0px;
  min-height: 10px;
  // line-height: 0.7;
}

.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 0px;
  // top: 0px;
}

.footer-text-timeline {
  font-size: 12px;
}

.ant-notification-notice-info {
  background-color: #5C5C5C;
  color: #fff;
}

.anticon.ant-notification-notice-icon-info {
  color: #fff;
}

.notification-button-dismiss-info {
  background-color: #8F8F8F;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  &:hover {
    color: #fff;
  }
}

.ant-notification-notice-success {
  background-color: #086336;
  color: #fff;
}

.anticon.ant-notification-notice-icon-success {
  color: #fff;
}
.ant-notification-notice-message {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.notification-button-dismiss-success {
  background-color: #0D9B54;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  &:hover {
    color: #fff;
  }
}

.error-message {
  font-size: 12px;
}

.error-cell {
  position: absolute;
  bottom: 0;
  font-size: 10px;
}

h2.ant-typography, .ant-typography h2 {
  font-size: 28px;
}

.ant-divider {
  border-top: 1px solid @grey-4;
}