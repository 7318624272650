@import url('../../styles/color.less');

#tee-sheet-take-payment-payment-method-item {
  .select-payment-type, .input-price {
		width: 100%;
	}
	.select-payment-type {
		color: @grey-3;
		font-weight: 600;
	}
	.input-price {
		.ant-input {
			text-align: right;
		}
	}
	.ant-table-footer {
		background-color: #fff;
		text-align: center;
		padding: 7.5px 16px;
		.ant-btn-text {
			color: @grey-3;
			font-size: 12px;
			font-weight: 400;
		}
	}
	.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 13.5px 16px;
	}
}