#lozenges.paid {
  height: 30px;
  background-color: #ECFEF5;
  border-radius: 8px;
  width: fit-content;
  padding: 3px 8px 3px 8px;
  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #0AD674;
  }
}

#lozenges.partially-paid {
  height: 30px;
  background-color: #FFF3EB;
  border-radius: 8px;
  width: fit-content;
  padding: 3px 8px 3px 8px;
  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #FF711F;
  }
}

#lozenges.unpaid {
  height: 30px;
  background-color: #FFEBEB;
  border-radius: 8px;
  width: fit-content;
  padding: 3px 8px 3px 8px;
  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #FF1F26;
  }
}