@import '../../styles/color.less';

.golf-cource-settings-container {
  overflow-y: auto;
  padding-left: 20px;
}

.golf-cource-settings {
  max-width: 710px;
  margin-top: 24px;
  .value {
    color: @dark;
    font-weight: 600;
    text-transform: capitalize;
  }
  .spacer {
    height: 4px;
  }
  .time-based {
    display: grid;
  }
  .button-add-setting {
    float: right;
    margin-right: 170px;
  }
  .button-add-setting-time-based {
    float: right;
    margin-right: 135px;
  }
}

.golf-cource-settings-card {
  margin-top: 24px;
}

.golf-cource-settings-card.last{
  margin-top: 24px;
  margin-bottom: 100px;
}