@import '../../styles/color.less';

.multiple-select {
    padding: 0px !important;
    width: fit-content !important;
}

#menu-item-multiple {
    padding: 10px;
}

.multiple-select-item .ant-select-selector .ant-select-selection-item {
    font-weight: 600;
    color: @grey-2;
}

.multiple-select-item .ant-select-arrow {
    color: @grey-2;
}

.multiple-select-item .ant-select-selector {
    border: 1px solid #5C5C5C !important;
    background-color: transparent !important;
}

.multi-select-label {
    margin-bottom: 8px;
    
    span {
        font-weight: 600;
        color: @grey-2;
    }
}

.multi-select-text {
    border: none;
}

.multi-select-full-width {
    width: 100%;
}