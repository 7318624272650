@import url('../../styles/color.less');
#tee-sheet-take-payment {
	.content-tab {
		margin-top: 24px;
	}
	.ant-list-item-meta-title {
		font-size: 16px;
	}
	.icon-dropdown {
		color: @primary-color
	}
}

#tee-sheet-take-payment.ant-list-item.menu-item {
	padding: 6px 0px;
	.ant-list-item-meta {
		align-items: center;
		.ant-list-item-meta-title {
			font-size: 14px;
			font-weight: 400;
		}
	}
}

#tee-sheet-take-payment.last-item {
	position: absolute;
	margin-top: 8px;
	background-color: @blue-5 !important;
	width: 100%;
	border-radius: 8px;
	box-shadow: 0px 8px 24px rgb(0 13 35 / 10%);
	height: 36px;
	text-align: center;
	font-weight: 600;
	color: @blue-3;
	font-size: 14px;
}