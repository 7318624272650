@import 'styles/color.less';

#spacer-course-profile {
  margin: 96px auto;
  padding-bottom: 80px;
  max-width: 706px;
}

.profile-pict {
  margin: 24px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image-uploader {
  .ant-typography {
    font-size: 14px;
    line-height: 20px;
    color: @grey-2;
    margin-bottom: 8px;
    display: block;
  }

  .drop-area {
    display: block;
    background: @grey-5;
    width: 222px;
    height: 222px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  label.drop-area {
    cursor: pointer;
  }

  .add-image-icon {
    width: 64px;
    height: 64px;
    color: @grey-3;
  }
}

.contact-profile {
  padding-bottom: 16px;

  .ant-typography {
    color: @grey-2;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    display: block;
  }

  .field {
    border: 2px solid @grey-5;
    height: 72px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;

    .start {
      display: flex;
      align-items: center;
      gap: 24px
    }

    .start {
      span {
        color: @grey-4;
      }

      .contact-value {
        color: @dark;
      }
    }
  }
}
