@import '../../styles/color.less';

.member-list {
  margin-bottom: 8px;
  text-align: left;
  .wrapper-title {
    margin: 16px 0;
    .initial-title {
      font-weight: 600;
    }
  }
  .list-card-item {
    margin-bottom: 6px;
    .ant-card-body {
      padding: 0px 26px;
    }
  }
  .spacer {
    height: 4px;
  }
}