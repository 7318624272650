@import '../../styles/color.less';

.antd-list-text-input {
  .label {
    font-size: 14px;
    font-weight: 600;
    color: @grey-2;
  }
  .icon-additional {
    align-self: center;
    color: @grey-3;
  }
  .input-text {
    margin-top: 8px;
  }
}