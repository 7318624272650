
.notes-wrapper {
  overflow: auto;
  height: 340px;
}

.notes-wrapper::-webkit-scrollbar {
  width: 10px;
}

.notes-today {

  .ant-card-body {
    padding: 24px 24px 0px;
  }

  .item {
    margin-bottom: 24px;
  } 
}