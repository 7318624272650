@import '../../styles/color.less';

.icon-button-svg {
  font-size: 18px;
  color: @grey-3
}

.icon-button-svg-primary {
  font-size: 18px;
  color: @primary-color
}

.button-svg {
  width: 36px;
  height: 36px;
}