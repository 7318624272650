@import '../../styles/color.less';

#customer-detail-reservation {
  .section {
    margin-top: 24px;
  }
  .name {
    font-weight: 600;
    font-size: 16px;
    margin-left: 16px;
  }
  .label {
    font-weight: 600;
    font-size: 14px;
    color: @grey-2;
  }
  .value {
    font-weight: 400;
    font-size: 14px;
    color: @grey-2;
  }
  .info {
    display: grid;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}