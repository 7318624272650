@import '../../styles/color.less';

.member-detail {
  text-align: left;
  margin-bottom: 8px;
  .wrapper-avatar {
    text-align: center;
    margin: 16px 0;
    .member-avatar {
      width: 164px;
      height: 164px;
      font-size: 40px;
      font-weight: 600;
      background-color: @green-2;
      padding: 65px;
    }
  }
  .title-member {
    font-weight: 600;
    font-size: 20px;
  }
  .member-info-divider {
    border-top: 0.5px solid @grey-4;
    color: @grey-4;
  }
  .wrapper-all-member-info {
    display: inline-grid;
    .wrapper-member-info {
      margin-top: 24px;
      display: inline-grid;
      .sub-title {
        font-weight: 600;
        font-size: 12px;
        color: @grey-2;
      }
    }
  }
}