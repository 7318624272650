@import '../../styles/color.less';

.member-settings {
  margin-bottom: 8px;
  .spacer {
    height: 4px;
  }
  #house-account {
    .ant-col {
      span {
        font-weight: 600;
        color: @blue-3;
      }
      .ant-card {
        .ant-card-body {
          padding: 10px 6px 10px 10px !important;
        }
      }
    }
  }
}