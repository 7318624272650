@import '../../styles/color.less';

#customer-form {
  .section-form {
    margin-top: 8px;
  }
  .ant-collapse-header {
    text-align: center;
  }
  .ant-collapse-content-box {
    padding: 0px;
  }
  .card-row {
    display: flex;
    justify-content: space-between;
  }
  .date-picker {
    margin-top: 8px;
    width: 100%;
  }
  .card-number {
    margin-top: 8px;
  }
  .user-icon {
    font-size: 138px;
    color: @grey-3;
    margin-bottom: 16px;
  }
  img.user-icon {
    width: 138px;
    height: 138px;
    margin: auto auto 16px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
  }
  .btn-upload {
    color: @blue-3;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    font-weight: 700;
  }
}
