@import url(../../styles/color.less);

#tee-sheet-reservation-form {
	.date-picker {
		width: 100%;
		margin-top: 8px;
		height: 52px;
		.ant-picker-input {
			flex-direction: row-reverse;
		}
		.ant-picker-suffix {
			margin-right: 6px;
			margin-left: 0px;
		}
	}
	.icon-tee-time {
		margin-right: 9px;
		color: @primary-color;
	}
	.placeholder {
		color: @grey-3;
	}
	.card-item {
		margin-top: 24px;
	}
	.button-add-container {
		text-align: center;
	}
	.section-field {
		margin-bottom: 24px;
		margin-top: 24px;
	}
	.ant-tabs {
		color: @grey-3;
	}
	.tab-pane {
		background-color: #fff;
	}
	.ant-tabs-tab.ant-tabs-tab-disabled {
		cursor: pointer;
	}
}