@import '../../styles/color.less';

.antd-horizontal-pin-text-input {
  .label {
    font-size: 14px;
    font-weight: 600;
    color: @grey-2;
    margin-right: 24px;
  }
  .container-text {
    width: 246px;
  }
  .input-text {
    height: 52px;
    width: 52px !important;
    left: 0px;
    top: 0px;
    border: 1px solid #C2C2C2;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
    margin-right: 4px;
    margin-top: 8px;
    color: @neutral-dark;
  }
}