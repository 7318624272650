@import '../../styles/color.less';

#edit-signs-form {
  width: 100%;
  display: flex;
  .title-edit-signs {
    margin-bottom: 0px;
    align-self: center;
  }
  .form-width-signs {
    width: 20rem;
  }
  .icon-edit-signs {
    margin-bottom: 8px;
    align-self: center;
  }
  .card-edit-signs-p {
    width: 570px;
    margin-top: 4px;
    margin-left: 48px !important;
    border: 2px solid #F5F5F5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
  }
  .card-edit-signs-c {
    margin-top: 4px;
    margin-left: 24px !important;
    border: 2px solid #F5F5F5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
  }
}