@import url('../../styles/color.less');

#tee-sheet {
	overflow-y: auto;
	.title-print {
		display: none;
	}
  .filter {
		border: 1px solid @grey-3;
		background-color: transparent;
		.ant-input {
			background-color: transparent;
		}
		width: 130px;
	}
	.search {
		width: 100px;
	}
	.button-add {
		margin-top: 2px;
		margin-bottom: 2px;
	}
	.content-tee-sheet {
		padding-top: 0px;
	}
	.footer-tee-sheet {
		text-align: center;
		padding-top: 39px;
		padding-bottom: 32px;
	}
	.spacer-horizontal {
		width: 16px;
	}
	.button-grey {
		background-color: @grey-3;
		color: #fff;
	}
	.header-sticky {
		top: 0px;
		position: sticky;
		z-index: 2;
		background-color: #fff;
	}
}

.body-print {
	& #tee-sheet {
		padding-left: 20px !important;
		padding-right: 20px !important;
		padding-top: 20px !important;
	}
	.header {
		display: none;
	}
	.title-print {
		display: block !important;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
	.footer-tee-sheet {
		display: none;
	}
}

.body-download {
	padding-left: 20px !important;
	padding-right: 20px !important;
	padding-top: 20px !important;
	.header {
		display: none;
	}
	.title-print {
		display: block !important;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
	.footer-tee-sheet {
		display: none;
	}
}

#header-content .search-input {
	width: 100% !important;
}