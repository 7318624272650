@import '../../styles/color.less';

.wrap-text-input {
  .label {
    font-size: 14px;
    font-weight: 600;
    color: @grey-2;
  }

  .input-text {
    margin-top: 8px;
  }
}

.antd-horizontal-text-input {
  //
}

.antd-inline-text-input {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 24px;
  width: 100%;

  .label {
    flex-grow: 1;
    max-width: 34%;
    text-align: right;
  }

  .wrapper-input {
    display: flex;
    justify-content: end;
    width: auto;
  }
}
