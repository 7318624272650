@import '../../styles/color.less';

#item-customer-detail-history {
  .number {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: @dark;
  }
  .icon {
    color: @grey-2;
    margin-right: 9px;
  }
  .label {
    font-size: 14px;
    font-weight: 400;
    color: @grey-2;
    line-height: 20px;
  }
  .value {
    font-size: 14px;
    font-weight: 600;
    color: @grey-2;
    line-height: 20px;
  }
  .item {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .col-right {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
}