@import '../../styles/color.less';

.antd-tabs {
  .ant-tabs-tab {
    font-size: 16px;
    font-weight: 600;
    color: @grey-3;
  }
  .ant-tabs-nav {
    border-bottom: 2px solid @blue-5;
  }
  .tab-bar-extra {
    color: @primary-color
  }
}