@import '../../styles/color.less';

.date-range-picker {
  border: 1px solid @grey-3;
  background-color: transparent;

  input {
    font-weight: 600;
    color: @grey-2;
    width: 85px;
  }
}