@import '../../styles/color.less';

.antd-horizontal-select-options {
  .label {
    font-size: 14px;
    font-weight: 600;
    color: @grey-2;
    margin-right: 16px;
  }
  .radio-button {
    margin-top: 8px;
  }
  .info {
    font-size: 16px;
    color: @grey-3;
  }
}