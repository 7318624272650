@import '../../styles/color.less';

.antd-horizontal-time-picker-range {
  text-align: left;
  .label {
    font-size: 14px;
    font-weight: 600;
    color: @grey-2;
    margin-right: 24px;
  }
  .time-picker-range {
    margin-top: 8px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    .ant-picker {
      width: 100%;
    }
  }
}