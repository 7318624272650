.rate-cards-form.rate-cards-form-table {
  margin-top: 24px;
  margin-bottom: 24px;
}

#rate-card-form {
  margin-left: auto;
  margin-right: auto;
  max-width: 728px;
  position: relative;
}

.block-view {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}