@import '../../styles/color.less';

.note {
  background-color: @grey-5;
  padding: 24px;
  border-radius: 8px;

  .course {
    font-size: 12px;
    color: @grey-2;
  }

  button {
    margin-left: -12px;
  }
}