@import '../../styles/color.less';

#header-content {
  .row-header-accounts {
    padding-top: 24px;
    padding-bottom: 24px;
    font-weight: 600;
    ::placeholder {
      color: @grey-3;
      font-weight: 600;
    }
  }
  .search-input {
    width: 200px;
  }
}