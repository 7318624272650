#tee-sheet-payment-success {
  .title {
    text-align: center;
  }
  .dropdown-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-list-item-meta-title {
    font-size: 16px;
  }
}