@import '../../styles/color.less';

#card-list-item {
  .ant-card {
    height: auto;
  }
  .icon {
    margin: 4px 0px 20px 4px;
  }
  .title {
    color: @blue-1;
    margin-bottom: 4px;
  }
  .desc {
    display: block;
    font-size: 12px;
  }
  .type-color {
    color: @grey-3;
  }
  .sum-customers {
    color: @blue-3;
  }
  .status-text, .paid-desc {
    font-weight: normal; 
    font-size: 12px;
    color: @grey-2;
  }
  .paid-desc {
    margin-bottom: 24px;
  }
}