@import '../../styles/color.less';

#antd-button.hint-primary {
  background-color: @blue-5;
  color: @blue-3;
}

#antd-button.hint-danger {
  background-color: @red-5;
  color: @red-3;
}

#antd-button.disabled-primary {
  background-color: @blue-4;
  color: #fff;
}

#antd-button.guide {
  background-color: @green-3;
  color: #fff;
}

#antd-button.disabled-guide {
  background-color: @green-4;
  color: #fff;
}

#antd-button.guide-text {
  color: @green-3;
}

#antd-button.outline {
  border-color: @primary-color;
  color: @primary-color;
}

#antd-button.disabled-outline {
  border-color: @blue-4;
  color: @blue-4;
}

#antd-button.default {
  color: @grey-3;
}

#antd-button.danger-text {
  color: @red-3;
}

#antd-button.secondary {
  background-color: @grey-3;
  border-color: @grey-3;
  color: white;
}

#antd-button.secondary:hover {
  border-color: @grey-3;
}

#antd-button.secondary.blue {
  background-color: @blue-5;
  border-color: @blue-5;
  color: @blue-3;
}
