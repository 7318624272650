@import url(../../styles/color.less);

.card-total {
  .text-total {
		color: @primary-color;
		font-size: 16px;
		font-weight: 600;
	}
	.price-total {
		color: @primary-color;
		font-size: 20px;
		font-weight: 600;
    margin-left: 24px;
	}
}