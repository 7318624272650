@import url(../../styles/color.less);

#tee-sheet-detail-reservation {
	position: relative;
	.card-item {
		margin-top: 24px;
	}
	.text-total {
		color: @primary-color;
		font-size: 16px;
		font-weight: 600;
	}
	.price-total {
		color: @primary-color;
		font-size: 20px;
		font-weight: 600;
		margin-left: 24px;
	}
	.tooltip {
		border-radius: 8px;
		background-color: @grey-2;
		width: 100%;
		padding: 16px;
		margin-bottom: 12px;
		color: #fff;
		.carret {
			position: absolute;
			right: 65px;
			height: 20px;
			width: 20px;
			background-color: @grey-2;
			margin-bottom: -5px;
			transform: rotate(45deg);
		}
	}
	.ant-tabs-nav {
    margin-bottom: 0px !important;
		margin-top: 10px;
	}
}