#rate-cards-form-table {
  .antd-table {
    .ant-table-content {
      padding: 0;
    }
    .ant-table-title {
      border: none !important;
      padding: 0;
    }
    .ant-table-container {
      border-left: none !important;
    }
    table {
      border: 1px solid #f0f0f0;
    }
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number {
    width: 100% !important;
  }
}