
@import '../../styles/color.less';

.dashboard-prepare {
  .text {
    color: @grey-2;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
  }

  .ant-progress-inner {
    background-color: @green-5;
  }

  .ant-progress-success-bg, .ant-progress-bg {
    background-color: @green-3;
  }
  
  .item {
    width: 323px;
    margin-right: 16px;

    .title {
      color: @green-3;
    }

    .icon {
      color: @green-3;
      font-size: 40px;
    }

    .svg-icon {
      svg {
        height: 40px;
        width: 40px;
        color: @green-3;
        margin-top: -4px;
        margin-bottom: -4px;
      }
    }
  }
}