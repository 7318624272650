@import '../../styles/color.less';

.tooltip-chart {
  background-color: @grey-2;
  color: white;
  border-radius: 8px;
  padding: 16px 12px;
  text-align: center;
  position: relative;
  width: 190px;

  .title {
    font-size: 16px;
    font-weight: 600;
  }

  .value {
    font-size: 14px;
  }

  .arrow {
    color: @grey-2;
    position: absolute;
    bottom: -10px;
  }
}