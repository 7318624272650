@import '../../styles/color.less';

.divider-role {
    margin: 16px 0 !important;
    color: @grey-4;
}

#checkbox-id, .ant-checkbox-inner, .ant-checkbox-checked::after {
    border-radius: 4px !important;
}

#icon-role {
    text-align: center;
    svg {
        margin-top: 7px;
        width: 26.67px;
        height: 26.67px;
    }
}

.btn-radio-wrapper {
    .ant-radio-group {
        background-color: @blue-5;
        border-radius: 8px;
        padding: 2px;
        .ant-radio-button-wrapper {
            border: none;
            background-color: @blue-5;
            height: 40px;
            span {
                color: @blue-4;
                font-weight: 600;
                font-size: 14px;
            }
        }
        .ant-radio-button-wrapper:not(:first-child)::before {
            width: 0px;
        }
        .ant-radio-button-wrapper-checked {
            background: #FFFFFF;
            border: none;
            border-radius: 8px;
            margin-top: 1px;
            box-shadow: none;
            height: 38px;
            span {
                color: @blue-3;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }
}

#role-setting-card {
    margin-bottom: 16px;
    .ant-radio-button-wrapper {
        width: 120px;
    }
    .checkbox-group {
        display: grid;
    }
}