@import '../../styles/color.less';

.raincheck-settings-container {
  overflow-y: auto;
  padding-left: 20px;
}

.raincheck-settings {
  max-width: 710px;
  margin-top: 24px;
  .value {
    color: @dark;
    font-weight: 600;
  }
  .spacer {
    height: 4px;
  }
  .time-based {
    display: grid;
  }
  .button-add-setting {
    float: right;
    margin-right: 170px;
  }
  .button-add-setting-time-based {
    float: right;
    margin-right: 160px;
  }
  .icon-delete {
    color: @grey-3;
    font-size: 16px;
  }
  .value-term {
    font-weight: 400;
  }
  ol {
    max-width: 400px;
  }
}

.raincheck-settings-card {
  margin-top: 24px;
}

.raincheck-settings-card.last{
  margin-top: 24px;
  margin-bottom: 100px;
}