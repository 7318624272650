@import url('../../styles/color.less');

#divider-tee-time {
	display: flex;
	align-items: center;
	margin-bottom: 21px;
	margin-top: 21px;
  .time {
		font-size: 12px;
		color: @grey-2;
	}
	.divider {
		background-color: @grey-4;
		border-radius: 8px;
		height: 2px;
		flex: 1;
		margin-left: 20px;
	}
}