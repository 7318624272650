@import url('../../styles/color.less');

#button-add-tee-time {
  display: flex;
	align-items: center;
	flex: 1;
	.button-plus-blocked {
		flex: 1;
		border: 2px solid @grey-5;
		height: 48px;
		margin-right: 16px;
		margin-left: 20px;
		background-color: #fff;
	}
	.button-plus {
		flex: 1;
		border: 2px solid @grey-5;
		height: 48px;
		margin-right: 16px;
		margin-left: 20px;
	}
	.button-blocked {
		width: 72px;
		height: 48px;
		background-color: @grey-5;
	}
	.time {
		font-size: 12px;
		color: @grey-2;
	}
	.icon-plus {
		color: @blue-4;
		font-size: 16px;
	}
	.button-icon {
		font-size: 16.67px;
		color: @grey-3;
	}
	.button-plus:hover {
		background-color: @blue-5;
		border-color: @blue-4;
		color: @blue-3;
	}
}