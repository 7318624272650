@import '../../styles/color.less';

.tee-times-settings-card {
  border: none !important;
  text-align: left;
  #timepicker-single-1,
  #timepicker-single-2 {
    word-spacing: 0em;
  }
  .suffix-interval {
    font-weight: 600;
    color: @grey-3;
  }
  .spacer-top {
    height: 96px;
  }
  .title-standard-section {
    font-weight: 600;
    font-size: 20px;
  }
}

.formik-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 728px;
  position: relative;
}

.tee-times-settings {
  margin-bottom: 8px;
  .spacer {
    height: 4px;
  }
  .spacer-top {
    height: 96px;
  }
  #house-account {
    .ant-col {
      span {
        font-weight: 600;
        color: @blue-3;
      }
      .ant-card {
        .ant-card-body {
          padding: 10px 6px 10px 10px !important;
        }
      }
    }
  }
}
