@import '../../styles/color.less';

.ant-input-prefix, .ant-input-suffix  {
    color: @grey-3;
}

.wrapper-input {
    width: 100%;
    .error-message {
        font-size: 12px;
    }
}

.border {
    border: 1px solid @grey-3;
}

.transparent {
    background-color: transparent !important;
}

.transparent input {
    background-color: transparent !important;
}

.search input {
    font-weight: 600 !important;
}

.search .ant-input-suffix {
    display: none;
}

.input-text-label {
    margin-bottom: 8px;
    
    span {
        font-weight: 600;
        color: @grey-2;
    }
}