#splash {
  display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	height: 100vh;
	
	.icon {
		font-size: 60px;
		animation: blinker 1s linear infinite;
	}

	@keyframes blinker {
		50% {
			opacity: 0;
		}
	}
}