@import '../../styles/color.less';

#customer-detail-information {
  .section {
    margin-top: 8px;
  }
  .avatar {
    width: 164px;
    height: 164px;
    line-height: 164px;
    font-size: 40px;
    text-align: center;
  }
  .value-left {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: @dark;
  }
  .value-right {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: @grey-3;
  }
}