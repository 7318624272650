@import '../../styles/color.less';

.antd-horizontal-text-input {
  display: block;
  text-align: left;
  .label-wrapper {
    align-self: baseline;
    padding-top: 16px;
  }
  .label {
    font-size: 14px;
    font-weight: 600;
    color: @grey-2;
    margin-right: 24px;
  }
  .input-text {
    margin-top: 8px;
    width: 100%;
  }
}
