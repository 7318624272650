@import url('../../styles/color.less');

#tee-sheet-item-customer-checkin {
	.ant-list-item-meta {
		align-items: center;
	}
	.ant-list-item-meta-title {
		font-size: 16px;
	}
	.item-list .ant-list-item-meta-title {
		font-size: 14px;
		margin-bottom: 0px;
		color: @grey-2;
	}
	.button-tag-id {
		border-color: transparent;
		font-weight: 400;
		height: 32px;
		line-height: 0px;
		width: 95px;
		text-align: center;
	}
	.button-status {
		background-color: @grey-2;
		color: #fff;
		font-size: 12px;
		font-weight: 600;
		height: 32px;
	}
	.ant-list-item {
		padding: 6px 0px;
	}
}