@import '../../styles/color.less';

#calendar-tee-time-form {
  .icon-tee-time {
    margin-right: 9px;
    color: @primary-color;
  }
  .placeholder {
    color: @primary-color;
  }
  .title-select-date {
    text-align: center;
    margin-bottom: 24px;
  }
  .button-custom-action {
    margin-left: -8px;
  }
  .checkbox-save-tee-time { 
    margin-right: -8px;
  }
  .border-divider {
    height: 1px;
    width: 100%;
    background-color: @grey-5;
    margin-left: -24px;
    margin-right: -24px;
    position: absolute;
    top: 72px;
  }
  .custom-tabs {
    margin-top: 48px;
  }
  .custom-select {
    padding-top: 48px;
  }
  .select-inclusions {
    width: 100%;
    margin-top: 4px;
  }
  .label-inclusions {
    font-size: 14px;
    font-weight: 600;
    color: @grey-2;
  }
  .label-times {
    font-size: 14px;
    font-weight: 600;
    line-height: 3;
    color: @grey-2;
  }
  .date-picker {
    margin-top: 8px;
    width: 100%;
  }
  .ant-table-content {
    overflow-x: auto;
    .ant-table-cell {
      min-width: 260px;
    }
  }
}
.label-option-tee-time {
  margin-left: 9px;
}
.dropdown-inclusions {
  padding: 16px;
  .ant-checkbox-wrapper {
    padding: 8px 0px 8px 0px;
  }
}