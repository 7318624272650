@import '../../styles/color.less';

.reservation-bar-chart {
  padding: 48px;
  border: 2px solid @grey-5;
  border-radius: 8px;
}

.recharts-layer .recharts-bar-rectangle path {
  fill: @blue-4;
}

.recharts-layer .recharts-bar-rectangle path:hover {
  fill: @blue-3;
}