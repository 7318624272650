@import '../../styles/color.less';

.antd-horizontal-static-input {
  .label {
    font-size: 14px;
    font-weight: 600;
    color: @grey-2;
    margin-right: 24px;
  }
  .input-static {
    width: 100%;
    margin-top: 8px;
  }
}