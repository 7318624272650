@import '../../styles/color.less';

.down-arrow {
  color: @blue-3;
}

.time-picker-naomi {
  margin-bottom: 8px;
  .ant-picker-input {
    input {
      word-spacing: 80px;
    }
    input:nth-word(1) {
      font-weight: 600;
    }
  }
}