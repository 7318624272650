@import '../../styles/color.less';

#customer-import-confirm {
  .content {
    margin-left: auto;
    margin-right: auto;
    max-width: 728px;
    padding-top: 70px;
    .title {
      margin-top: 80px;
      margin-bottom: 48px;
    }
    .item-info {
      margin-left: 16px;
    }
    .item-name {
      margin-bottom: 0px;
    }
    .item-desc {
      font-size: 12px;
    }
    .spinner {
      text-align: center;
      margin-top: 80px;
    }
    .spinner-animate {
      color: @blue-4;
    }
    .text-spinner {
      margin-top: 20px;
    }
  }
}