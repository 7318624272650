#customer-filter-type {
  .radio-group {
    width: 100%;
  }
  .checkbox-group {
    display: grid;
    margin-left: 25px;
  }
  .ant-checkbox-group-item {
    margin-bottom: 16px;
  }
  .ant-collapse-header, .ant-collapse-content-box {
    padding: 0px !important;
  }
}