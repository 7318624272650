@import '../../styles/color.less';

.antd-horizontal-color-picker {
  .label {
    font-size: 14px;
    font-weight: 600;
    color: @grey-2;
  }
  .color-picker {
    margin-top: 8px;
  }
}