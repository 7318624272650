@import '../../styles/color.less';

#radio-button-tab {
  .ant-radio-group {
    background-color: @grey-5;
    border-radius: 8px;
    padding: 2px;
    display: flex;
    .ant-radio-button-wrapper {
      border: none;
      background-color: @grey-5;
      flex: 1;
      text-align: center;
      span {
          color: @grey-3;
          font-weight: 600;
          font-size: 14px;
      }
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      width: 0px;
    }
    .ant-radio-button-wrapper-checked {
      background: #FFFFFF;
      border: none;
      border-radius: 8px;
      box-shadow: none;
      span {
          color: @blue-3;
          font-weight: 600;
          font-size: 14px;
      }
    }
  }
}