
.reservation-steps {
  .ant-steps-item-content {
    display: flex !important;
    justify-content: space-between;
    min-height: 35px !important;
    padding-top: 4px;
  }

  .ant-steps-item-title {
    font-size: 12px !important;
    line-height: 16px !important;
  }

  .ant-steps-item-description {
    font-size: 12px !important;
    line-height: 16px !important;
  }
}