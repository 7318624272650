@import '../../styles/color.less';

.ant-layout-header {
  height: 68px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 68px;
  background: #fff;
  box-shadow: 0px 16px 50px rgba(92, 92, 92, 0.05);
  z-index: 3;
  position: fixed;
  left: 0px;
  right: 0px;
}

#app-bar.ant-row {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

#app-bar {
  .title {
    margin-left: 16px;
    color: @grey-2 !important;
    margin-top: 5px;
  }

  .row-title {
    flex-direction: 'row';
  }

  .avatar {
    background-color: #000D23;
    vertical-align: middle;
    font-size: 12px;
    padding-left: 1px;
    margin-left: 24px;
    cursor: pointer;
  }

  .button-guide {
    margin-right: 24px;
    background-color: @green-3;
    color: #fff;
    font-weight: 600;
  }


  .button-guide.ant-btn:hover, .button-guide.ant-btn:focus {
    color: @grey-5;
    background-color: @green-3;
  }

  .icon-menu {
    margin-left: 24px;
  }
}
