.color-picker.container {
  width: 100%;
}

.color-picker.drop-down {
  width: 360px !important;
  padding: 8px 16px 16px 8px;

  .color-item {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    margin-left: 8px;
    margin-top: 8px;
    border: 1px solid #F5F5F5;
    padding: 0;
  }
}