@import '../../styles/color.less';

#rate-cards-form-inclusions {
  margin-top: 90px;
  .icon-info {
    color: @grey-3;
    font-size: 14px;
    margin-top: 6px;
  }
  .item-inclusion {
    margin-bottom: 4px;
  }
  .list-inclusion {
    width: 100%;
    margin-top: 16px;
  }
  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
  }
  .label {
    font-size: 14px;
    font-weight: 600;
  }
  .rate-card-form-inclusions {
    .value {
      font-size: 14px;
      font-weight: 600;
      color: @blue-4;
    }
  }
}