@import '../../styles/base.less';
@import '../../styles/color.less';

#rate-cards-empty.container {
  border-radius: @border-radius-base;
  height: 100%;
  background-color: @grey-5;
  margin: 24px 0px 58px 24px;
}

#rate-cards-empty {
  .title {
    font-weight: 600;
    font-size: 40px;
    color: @dark;
  }
  .row {
    height: 100%;
  }
  .left {
    max-width: 430px;
    margin-left: auto;
    margin-right: auto;
  }
  .desc {
    font-size: 16px;
    line-height: 24px;
  }
  .button {
    display: block;
    margin-top: 56px;
    margin-right: 4px;
  }
}