@import '../../styles/color.less';

#customer-import {
  .download-text {
    color: @primary-color;
    font-weight: 600;
  }
  .wrapper-text {
    margin-bottom: 24px;
  }
}