@import '../../styles/color.less';

.row-header-accounts {
    margin-top: 8px;
    margin-bottom: 24px;
}

.second-menu-edit {
    color: @red-2;
}

#input-text-account{
    .ant-input-affix-wrapper {
        border: 1px solid #8F8F8F;
        .ant-input {
            font-weight: 600;
        }
    }
}

#input-multiple-account {
    .ant-select {
        .ant-select-selector {
            border: 1px solid #8F8F8F !important;
            .ant-select-selection-item {
                color: #8F8F8F;
            }
        }
    }
}