@import '../../styles/color.less';
#drop-file {
  border: 1px dashed @primary-color;
  border-radius: 8px;
  padding: 24px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 492px;
  cursor: pointer;
  min-height: 97px;
  .text {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 24px;
    color: @primary-color;
  }
  .icon-upload {
    color: @primary-color;
    font-size: 40px;
    margin-right: 28.05px;
  }
  .text-file {
    font-weight: 600;
    font-size: 16px;
  }
  .icon-delete {
    font-size: 18px;
    color: @grey-3;
  }
}

#drop-file.file-exists {
  border: 1px solid @primary-color;
  justify-content: flex-start;
  flex-grow: 1;
}