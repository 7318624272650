@import '../../styles/base.less';

#sider-drawer {
  border-right: none;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  height: 100%;
  overflow: auto;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-item:active, 
.ant-menu-submenu-title:active {
  border-radius: @border-radius-base;
}

.drawer-item {
  font-weight: 600;
}

.ant-layout-sider-collapsed {
  #sider-drawer {
    padding-left: 24px;
    padding-right: 20px;

    .ant-menu-item, .ant-menu-submenu-title {
      padding-left: 11px !important;
    }
    .ant-menu-title-content, .-title-content {
      margin-left: 20px !important;
    }
    .ant-menu-submenu-arrow {
      display: none;
    }
  }
}