@import '../../styles/color.less';

#item-detail-text {
  padding-top: 12px;
  padding-bottom: 12px;
  display: grid;
  .label {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    color: @grey-2;
  }
  .value {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: @dark;
  }
  .container-value {
    margin-top: 4px;
  }
}