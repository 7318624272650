@import '../../styles/color.less';

#multiple-select-date {
  position: relative;
  table {
    border-radius: 12px;
    overflow: hidden;
    margin-left: -1px;
  }
  .ant-picker-body {
    padding: 0px;
    border: 0.5px solid @grey-4;
    border-radius: 12px;
  }
  .select-all-day {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 100px;
    z-index: 3;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    height: 50px;
    text-align: center;
    line-height: 18px;
    background-color: @grey-5;
    font-size: 12px;
    color: @grey-2;
    border-left: 0.5px solid @grey-4;
    border-bottom: 0.5px solid @grey-4;
    font-weight: 600;
    padding: 0px 0px 20px 0px;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border: 0px;
    margin: 0px;
    height: 72px;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-picker-cell {
    background-color: @grey-5;
    color: @grey-5;
    border: 0px solid @grey-4;
  }
  .ant-picker-cell-in-view {
    color: @grey-2;
    background-color: transparent;
    border: 0.5px solid @grey-4;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    height: 45px;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
    background: transparent;
    color: @grey-2;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    color: @grey-2;
  }
}

#header-multiple-select-date {
  .header {
    border: 0.5px solid #C2C2C2;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 8px;
  }
  .title {
    font-size: 16px;
    font-weight: 600;
  }
}

#content-multiple-select-date {
  &.date-cell {
    text-align: center;
    margin-top: 5px;
  }
}