@import '../../styles/color.less';

.form-input-text {
  margin-top: 16px;
  .wrapper-input {
    display: block;
  }
}

.logo-wrapper-login {
  svg {
    margin-bottom: 8px;
    width: 24px;
    height: 24px;
  }
}

.button-submit-login {
  width: 100%;
  margin: 16px 0;
}

.forgot-account-wrapper {
  text-align: right;
  span {
    margin-right: 16px;
    font-weight: 600;
    color: @blue-3;
  }
}