@import '../../styles/color.less';

.antd-drawer-class {
  .ant-drawer-close {
    left: 35.75px;
    top: 4px;
    right: unset;
  }
  .ant-drawer-title {
    text-align: left;
    margin-top: 4px;
    color: @grey-2;
    margin-left: 68px;
    font-size: 16px;
    font-weight: 600;
  }
  .button-action-drawer {
    position: absolute;
    right: 35.75px;
    top: 15px;
  }
}