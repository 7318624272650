#account-settings {
  .spacer {
    height: 4px;
  }
  .ant-card-body {
    .ant-row {
      .ant-col {
        input {
          width: 680px;
        }
        textarea {
          width: 680px;
        }
      }
    }
  }
}