@import url('../../styles/color.less');

#calendar-daily-view {
  .column {
    background-color: @grey-5;
    border-radius: 8px;
    .ant-typography {
      color: @grey-2;
      font-size: 14px;
      font-weight: 600;
    }
    .time {
      width: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid @grey-4;
      height: 40px;
    }
    .tee-times {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      border-right: 1px solid @grey-4;
      height: 40px;
    }
    .rate-cards {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      height: 40px;
    }
  }
  .rbc-time-view-resources .rbc-time-header {
    overflow: hidden;
    display: none;
  }
  .rbc-label {
    font-size: 12px;
    font-weight: 400;
    padding: 0px;
    color: @grey-2;
  }
  .rbc-time-gutter > .rbc-timeslot-group > .rbc-time-slot {
    margin-top: -21px;
    width: 62px;
  }
  .rbc-timeslot-group {
    min-height: 62px;
  }
  .rbc-timeslot-group {
    border-bottom: none;
  }
  .rbc-time-content > * + * > * {
    border-left: none;
  }
  .rbc-time-content {
    border-top: none;
    margin-top: 24px;
    padding-top: 21px;
  }
  .rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter {
    border-right: none;
  }
  .rbc-time-view {
    border: none;
  }
  .rbc-day-slot .rbc-time-slot {
    border-top: none;
  }
  .rbc-day-slot .rbc-time-slot:nth-child(1) {
    border-top: 0.5px solid @grey-4;
  }
  .rbc-day-slot .rbc-time-slot:nth-child(30) {
    border-top: 0.5px solid @grey-4;
  }
  .rbc-day-slot .rbc-event-label {
    top: 30px;
    position: absolute;
  }
  .title-event-1 {
    margin-top: 5px;
    color:inherit;
    font-weight: 600;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .title-event-2 {
    margin-top: 5px;
    color: inherit;
    font-weight: 600;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .time-event-1 {
    .rbc-event-label {
      font-size: 12px;
      color: #fff;
    }
  }
  .time-event-2 {
    .rbc-event-label {
      font-size: 12px;
      color: inherit;
    }
  }
  .rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor .rbc-addons-dnd-resize-ns-icon {
    border-top: 5px double;
    width: 20px;
  }
  .rbc-day-slot .rbc-event-content {
    margin-top: 10px;
  }
}