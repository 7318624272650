@import '../../styles/color.less';

.wrap-select-options {
  .label {
    font-size: 14px;
    font-weight: 600;
    color: @grey-2;
  }
}

.antd-horizontal-select-options {
  display: block;
  text-align: left;

  .label {
    margin-right: 16px;
  }

  .select-option {
    margin-top: 8px;
    width: 100%;
    text-align: left;
    .ant-select-selector {
      background-color: @grey-5 !important;
      border-color: @grey-5;
    }
  }
  .ant-select-selection-placeholder {
    color: @primary-color;
  }
}

.antd-inline-select-options {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 24px;
  width: 100%;

  .label {
    flex-grow: 1;
    max-width: 34%;
    text-align: right;
  }
}
