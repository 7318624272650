@import '../../styles/color.less';

.table-report {
  padding: 48px;
  width: 100%;
  font-size: 12px;
  border: 2px solid @grey-5;
  border-radius: 8px;

  .expand-icon {
    margin-right: 4px;
    color: @primary-color;
  }

  .row-total td {
    border-top: 1px solid#C2C2C2;
    font-weight: 600;
    color: @blue-2;
  }

  .row-expandable {
    color: @text-color;
  }

  .row-expandable td {
    border-top: 1px solid #f0f0f0;
  }

  .row-children {
    color: @grey-2;
  }

  .row-children td {
    border-bottom: none;
  }

  .row-footer td {
    font-weight: 600;
    background-color: @blue-2;
    color: white;
  }
}

.table-report-wrapper {
  border: 1px solid#C2C2C2;
  border-radius: 8px;
}

.table-report th {
  font-weight: 600;
  font-size: 12px;
  background: @grey-5 !important;
  border-bottom: 1px solid#C2C2C2 !important;
}

.table-report th::before {
  width: 0px !important;
}

.table-report td {
  font-size: 12px;
  padding: 8px 16px!important;
}

.table-report-title {
  margin-bottom: 24px !important;
}

.table-report-cell {
  padding: 16px;
}

.table-report-extra-footer {
  margin-top: 24px;
  padding: 8px 0px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #C2C2C2;
  font-weight: 600;
  color: @blue-2;
}

.table-report-footer {
  margin-top: 24px;
  padding: 8px 0px;
  background-color: @blue-2;
  border-radius: 8px;
  color: white;
  overflow: hidden;
}