@import url('../../styles/color.less');

#tee-sheet-reservation-customer-form {
  .ant-table table {
		border-radius: 0 0 8px 8px;
	}
	.avatar {
		font-size: 20px;
		color: @grey-3;
	}
	.title-container {
		background-color: @grey-5;
		height: 64px;
		padding-left: 20px;
		padding-right: 10px;
	}
	.ant-table-title {
		padding: 0px;
	}
	.title-user {
		display: flex;
		align-items: center;
	}
	.title-user-name {
		margin-left: 22px;
		font-weight: 600;
	}
	.icon-prefix-input {
		color: @grey-4;
		font-size: 13.29px;
		margin-right: 5px;
	}
	.select-option {
		width: 100%;
	}
	.icon-select-option {
		text-align: center;
		margin-top: 4px;
    margin-left: 8px;
		color: @grey-4;
		font-size: 13.29px;
	}
	.cell-total {
		background-color: #fff !important;
		text-align: right;
	}
	.error-cell {
		position: absolute;
		bottom: 0;
		font-size: 10px;
	}
}
.item-multiple-select-option {
	padding: 10px 16px 10px 16px;
	width: 100%;
}
.dropdown-multiple-select-option {
	min-width: 300px !important;
}
.checkbox-multiple-select-option {
	width: 100%;
}
.row-multiple-select-option {
	width: 245px;
}
.context-menu-clear {
	color: @dark;
}
.drop-down-width-500 {
	width: 500px !important;
}