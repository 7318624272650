@import '../../styles/color.less';

.status-insights {
  .title {
    margin-left: 16px;
    color: @grey-2;
    font-weight: 600;
  }

  .ant-card-body {
    padding: 12px 24px;
  }
}