@import '../../styles/color.less';

#button-status-reservation {
	.button-status {
		background-color: @grey-2;
		color: #fff;
		font-size: 12px;
		font-weight: 600;
	}

	.button-status-checkedin {
		background-color: @blue-2;
		color: #fff;
		font-size: 12px;
		font-weight: 600;
	}

  .button-status-paid {
		background-color: #086336;
		color: #fff;
		font-size: 12px;
		font-weight: 600;
	}

	.button-status-cancel {
		background-color: #B80006;
		color: #fff;
		font-size: 12px;
		font-weight: 600;

		svg {
			margin-bottom: -1px;
		}
	}
}