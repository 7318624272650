@import '../../styles/color.less';

.icon-action {
  color: @grey-3;
  font-size: 18px;
  margin-top: 3px;
  font-size: 18px;
}

.button-action,
.button-action.ant-btn:hover, .button-action.ant-btn:focus {
  background-color: @grey-5;
  border-color: @grey-5;
  height: 36px;
  width: 36px;
}