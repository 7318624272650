@import '../../styles/color.less';

.antd-modal {
  padding-bottom: 0px;
  .left-footer-button {
    float: left;
    min-width: 78px;
    margin-left: 0px !important;
  }
  .right-footer-button {
    min-width: 78px;
  }
  .ant-modal-close {
    left: 14px;
    top: 4px;
    right: unset;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
  .ant-modal-header {
    height: 64px;
    padding: 20px 24px;
    box-shadow: 0px -0.5px 0px #C2C2C2, 0px 0.5px 0px #C2C2C2;
    z-index: 1;
  }
  .ant-modal-footer {
    height: 68px;
    padding: 15px 24px;
    box-shadow: 0px -0.5px 0px #C2C2C2, 0px 0.5px 0px #C2C2C2;
  }
  .ant-modal-body {
    background-color: #fff;
    margin-top: 2px;
    max-height: calc(~"100vh - 240px");;
    overflow-y: auto;
    overflow-x: hidden;
  }
}