@import '../../styles/color.less';

.antd-horizontal-date-range {
  display: block;
  text-align: left;
  .label {
    align-self: baseline;
    padding-top: 16px;
    color: @grey-2;
    font-weight: 600;
    margin-right: 24px;
  }
  .date-range {
    margin-top: 8px;
    background-color: @grey-5;
    border-color: @grey-5;
    width: 100% !important;
  }
} 