@import url('../../styles/color.less');

#filled-tee-time {
  display: flex;
	.button-filled {
		flex: 1;
		margin-right: 16px;
		margin-left: 20px;
		padding-bottom: 24px;
		padding-top: 24px;
		cursor: pointer;
	}
	.time {
		font-size: 12px;
		color: @grey-2;
	}
	.icon-plus {
		color: @blue-4;
		font-size: 16px;
	}
	.button-icon {
		font-size: 16.67px;
		color: @grey-3;
	}
	.button-icon-note {
		font-size: 16.67px;
		color: @red-3;
		background-color: @red-5;
	}
	.item-customer:hover {
		background-color: @blue-5;
		border-color: @blue-4;
		color: @blue-3;
	}
	.item-customer {
		margin-bottom: 2px;
		cursor: pointer;
	}
	.ant-card-body {
		padding-top: 0px;
		padding-bottom: 0px;
	}
	.ant-list-item-meta-description {
		font-size: 12px;
		margin-top: -5px;
	}
	.button-status {
		background-color: @grey-2;
		color: #fff;
		font-size: 12px;
		font-weight: 600;
	}

	.button-status-checkedin {
		background-color: @blue-2;
		color: #fff;
		font-size: 12px;
		font-weight: 600;
	}

	.raincheck-icon {
		margin-left: 4px;
		background-color: #086336;
		color: white;
	}

	.bag-id {
		margin-right: 24px;
		font-weight: 600;
		color: @grey-2;
	}

}

.tooltip-raincheck {
	white-space: pre-line;
}