#customer-filter-nationality {
  .checkbox-group {
    display: grid;
    margin-left: 25px;
  }
  .ant-checkbox-group-item {
    margin-bottom: 16px;
  }
  .search {
    margin-bottom: 8px;
  }
}