@import '../../styles/color.less';

#calendar {
  &.calendar-container {
    overflow-y: auto;
    padding-left: 20px;
  }

  .header {
    margin-top: 24px;
    margin-bottom: 8px;
  }
  .title {
    margin-bottom: 0px;
  }
  .ant-picker-content {
    border-left: 0.5px solid @grey-4;
    border-top: 0.5px solid @grey-4;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    height: 46px;
    text-align: center;
    line-height: 18px;
    background-color: @grey-5;
    font-size: 12px;
    color: @grey-2;
    border-right: 0.5px solid @grey-4;
    border-bottom: 0.5px solid @grey-4;
    font-weight: 600;
    padding: 0px;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    border: 0px;
    margin: 0px;
    height: 202px;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-body th, .ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
    border-right: 0.5px solid @grey-4;
    border-bottom: 0.5px solid @grey-4;
    overflow-y: hidden;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
  }
  .ant-picker-cell-in-view {
    color: @grey-2;
  }
  .card-cell {
    border-radius: 4px;
    padding: 4px;
    display: flex;
    flex: 1;
    margin: 1px;
  }
  .text-card-cell {
    font-size: 12px;
    font-weight: 600;
  }
  .wrapper-date {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    height: 100%;
  }
}