@import '../../styles/color.less';

.row-header-accounts {
    margin-top: 8px;
    margin-bottom: 24px;
}

.second-menu-edit {
    color: @red-2;
}

#input-text-roles{
    .ant-input-affix-wrapper {
        border: 1px solid #8F8F8F;
        .ant-input {
            font-weight: 600;
        }
    }
}